import { React, useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchLocationInfo } from "../../features/shopLocation/locationSlice";
import { fetchShopLocationInfo } from "../../features/shopLocation/ShoplocationSlice"
import { fetchTopSliderInfo } from "../../features/shopLocation/topSliderSlice"
import apiConfig from "../../apiConfig";
import { useSearchParams } from "react-router-dom";
import DocumentMeta from 'react-document-meta';
import {getCurrentPageTitle} from "../../features/currentPage"

export const Header = ({ value, updateShop }) => {
  const buttonRef = useRef(null);
  const [location, setLocation] = useState([]);
  const dispatch = useDispatch();
  const [menu, setMenu] = useState([]);
  const [menuMain, setMenuMain] = useState([]);

  const baseUrl = process.env.PUBLIC_URL;

  const [searchParams, setSearchParams] = useSearchParams();

  const [isOpen, setIsOpen] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiConfig.apiBasePath}getNearShopLocation`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        console.log('result',result)
        // setLocations(result);
      } catch (error) {
        // setError(error.message);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Function to toggle the menu
  const toggleMenu = () => {
    
    if(isOpen != 'open')
    {
      setIsOpen('open');
    }else{
      setIsOpen('');
    }
    
    setMenuMain([])
  };

  // const location = [{ name: 'Dessau', Id: 72 },
  // { name: 'Duderstadt', Id: 2 },
  // { name: 'Halberstadt', Id: 68 },
  // { name: 'Jena', Id: 69 },
  // { name: 'Sangerhausen', Id: 3 },
  // { name: 'Quedliburg', Id: 73 },
  // { name: 'Bitterfeld-Wolfen', Id: 70 },
  // { name: 'Wernigerode', Id: 71 }];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiConfig.apiBasePath}locationList`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        setLocation(result);
      } catch (error) {
        // setError(error.message);
      } finally {
        // setLoading(false);
      }
    };

    
    dispatch(fetchShopLocationInfo())
    dispatch(fetchTopSliderInfo())
    fetchData();
    
  }, []);

  let CurrentShop = useSelector((state) => state?.location?.value?.kundenid);
  const PartnerList = useSelector((state) => state?.partner?.value);
  const routeLocation = useLocation();
  const { hash, pathname, search } = routeLocation;
  let selectedLocation = "";
  const { partnerName, modalName } = useParams();
  if(modalName){
    let urlLocation = location.filter((l)=>l.ORT.toLowerCase() == modalName.toLowerCase());
    if(urlLocation?.length > 0){
      localStorage.setItem("selectLocation", JSON.stringify(urlLocation[0]));
      dispatch(fetchLocationInfo(urlLocation[0]))
      navigate(pathname.replace(`/${modalName}`, ""));
      return;
    }
  }
  if (Array.isArray(location)) {
    let tmlocation = location;

    //  selectedLocation = (CurrentShop) ? tmlocation.filter((shop) => parseInt(shop.ID, 10) ===  parseInt(CurrentShop, 10)) :  [{ name: 'Wernigerode' }];
    selectedLocation = CurrentShop
      ? tmlocation.filter((item) => item?.locations_id == CurrentShop)
      : [{ ORT: "Wernigerode" }];

    const currentDate = new Date();
    const selectLocationTime = localStorage.getItem("selectLocationTime");
    if(!selectLocationTime)
      localStorage.setItem("selectLocationTime", currentDate.getTime());

    if(selectLocationTime){
      if(currentDate.getTime()-selectLocationTime*1 > 1000*60*60*24){ // 1000*60*60*24 = 24 hours
        localStorage.removeItem('selectLocationTime');
        localStorage.removeItem('selectLocation');
        localStorage.removeItem('state');
      }
    }
    const selectLocation = localStorage.getItem("selectLocation");
    if(selectLocation)
      selectedLocation = [JSON.parse(selectLocation)];

  }

  const LocationupdateShop = (locations) => {
    updateShop(locations);
    console.log("selected locations",locations);
    localStorage.setItem("selectLocation", JSON.stringify(locations));
    setMenu("");
    dispatch(fetchTopSliderInfo())
  };

  const handleAnchorClick = () => {
    // Trigger button click when anchor is clicked
    buttonRef.current.click();
  };


let title = '';

  const pageTitle = getCurrentPageTitle(pathname);
  if(partnerName){

     title = `MEDIMAX ${selectedLocation[0]?.ORT} - Ihr lokaler Partner für  ${ partnerName.charAt(0).toUpperCase() + partnerName.slice(1)}`;
  }else if(pageTitle){
    title = `MEDIMAX ${selectedLocation[0]?.ORT} - ${ pageTitle} `;
  }else{
    title = `MEDIMAX ${selectedLocation[0]?.ORT} - Ihr Partner für Ihre Technik`;

  }
  // const title = `MEDIMAX ${selectedLocation[0]?.ORT} - ${(pageTitle)? '':''}Ihr ${ (partnerName)? 'lokaler':'' } Partner für  ${(partnerName)? partnerName.charAt(0).toUpperCase() + partnerName.slice(1): pageTitle || 'Ihre Technik'}`;
  const meta = {
    title: title,
    meta: {
      property: {
        "og:title": title
    },
    
  }
  }
  return (
    <>
    <DocumentMeta {...meta} />
    <div className="container-fluid ">
      <div className="row align-items-stretch">
        <div className=" col-xl-4 col-lg-3 p-0 text-end logoWrap">
          <NavLink to="/">
            <img
              className="logoMain"
              src={baseUrl + "/assets/images/MEDIMAX-2021_Logo.png"}
              alt=""
            />
          </NavLink>
        </div>{" "}
        <div className="col-xl-4 col-lg-5 p-0 text-center position-relative yellow-stripe">
          <div className="menuSearch d-flex justify-content-between align-items-center">
            <button
            onClick={toggleMenu}
              className="toogle_menu border-0 bg-transparent d-flex align-items-center"

            >
              <img
                src={baseUrl + "/assets/images/Icon_Menü.png"}
                alt=""
                className="me-3"
              />
              Menü
            </button>
            <div className={`dropMenu-location text-start ${isOpen} ${menuMain}`}>
              <ul>
                <li>
                  <NavLink
                    to="/werbung"
                    className="nav__link toogle_menu border-0 bg-transparent d-flex align-items-center"
                    onClick={() => setIsOpen("")}
                  >
                    Aktuelle Werbung
                  </NavLink>
                </li>
                <li>
                <NavLink
                    to="/aktionen"
                    className="nav__link toogle_menu border-0 bg-transparent d-flex align-items-center"
                    onClick={() => setIsOpen("")}
                  >
                    Aktionen
                  </NavLink>
                   </li>
                <li>
                <NavLink
                    to="/partners"
                    className="nav__link toogle_menu border-0 bg-transparent d-flex align-items-center"
                    onClick={() => setIsOpen("")}
                  >
                  Partner
                  </NavLink>
                  <ul>
                     {Array.isArray(PartnerList) &&
                      PartnerList.map((partner, i) => (
                        <li key={i}>
                          <NavLink
                            // onClick={handleAnchorClick}
                            onClick={() => setIsOpen("")}
                            to={"/partner/" + encodeURIComponent(partner.slug)}
                            className="nav__link toogle_menu border-0 bg-transparent d-flex align-items-center"
                          >
                            {partner.name}
                          </NavLink>
                        </li>
                      ))} 
                  </ul>
                </li>
                <li>
                  <NavLink
                    to="/artikel"
                    onClick={() => setIsOpen("")}
                    className="nav__link toogle_menu border-0 bg-transparent d-flex align-items-center"
                  >
                    Artikelsuche
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/maxithek"
                    className="nav__link toogle_menu border-0 bg-transparent d-flex align-items-center"
                  >
                    MAXITHEK
                  </NavLink>
                </li>
                <li>
                  <NavLink
                  onClick={() => setIsOpen("")}
                    to="/filialen"
                    className="nav__link toogle_menu border-0 bg-transparent d-flex align-items-center"
                  >
                    Kontakt
                  </NavLink>
                </li>              
                
              </ul>
            </div>
            <form className="header-search" method="get" action={process.env.PUBLIC_URL+ '/artikel'}>
              <input type="text" placeholder="Suche" name="search" value={searchParams.get("search")}/>
              <input type="submit" />
            </form>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 p-0 text-start last-area-header position-relative">
          <div className="cart-location-area d-flex justify-content-end align-items-center">
            <div className={`locationBtn position-relative ${menu}`}>
              <button
                className="locationToggle"
                onClick={() => setMenu("open")}
              >
                <img
                  src={baseUrl + "/assets/images/Icon_Standort.png"}
                  alt=""
                />
                <span>
                  {Array.isArray(selectedLocation) && selectedLocation[0]?.ORT}
                </span>
                <img
                  className="ms-3 me-0"
                  src={baseUrl + "/assets/images/Icon_Dropdown.png"}
                  alt=""
                />
              </button>
              <div className="dropMenu-location">
                <ul>
                  {Array.isArray(location) &&
                    location.map((location, i) => (
                      <li
                        key={i}
                        className="locationMenuToggle"
                        onClick={() =>
                          LocationupdateShop(location)
                        }
                      >
                        {location.ORT}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>

  );
};

const mapStateToProps = (state) => ({
  value: state.location.value,
});

const mapDispatchToProps = (dispatch) => ({
  updateShop: (locations) => dispatch(fetchLocationInfo(locations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
