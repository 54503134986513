module.exports.getCurrentPageTitle = (pathname) =>{
    switch(pathname){
        case "/maxithek":
            return "MAXITHEK"

        case "/werbung":
            return "AKTUELLE WERBUNG"

        case "/partners":
            return "PARTNERS"

        case "/aktionen":
            return "KONTAKT"

        case "/artikel":
            return "ARTIKELSUCHE"
        
        case "/filialen":
            return "KONTAKT"

        default:
            return "";

    }
}



// export default getCurrentPageTitle