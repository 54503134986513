import { Routes, Route, BrowserRouter,Navigate } from "react-router-dom";
import { useState } from 'react'
import  Home  from "./components/Home/Home";
import  Layout  from "./components/layouts/layout";
import './App.css';
import AktuelleWerbung from "./components/CurrentAdvertising/AktuelleWerbung";
import VideoLibrary from "./components/VideoLibrary/VideoLibrary";
import CookiesBanner from "./components/CookiesBanner";
import PartnerDetail from "./components/Partners/PartnerDetail";
import Articles from "./components/Articles/Articles";
import ProductDetailPage from "./components/Articles/ProductDetailPage";
import ContactUs from "./components/ContactUs/ContactUs";
import { useCookies } from "react-cookie";
import PartnersList from "./components/Partners/PartnersList";
import WeeklyHighlight from './components/weeklyHighlight/WeeklyHighlight';


const App = () => {
  const [Article, setupdateArticle] = useState({});
  const [cookies, setCookie] = useCookies(["cookiesAccepted"]);
  const [isVisible, setIsVisible] = useState(!cookies.cookiesAccepted);

  const handleLocationChange = (value:any) => {
    setupdateArticle(value);
};

  return (
    <div className="App">
      <CookiesBanner />
    <BrowserRouter basename={process.env.PUBLIC_URL} >
      <Routes>
        <Route element={<Layout cLocation={handleLocationChange} />} >
          
          <Route path="/:modalName?" >
          <Route path=""  element={<Home currentLocation={Article}  />} />
          <Route path="maxithek" element={<VideoLibrary />} />
          {/* <Route path="/AktuelleWerbung/:locationId?/:itemsId?" element={<AktuelleWerbung />} /> */}
          <Route path="werbung/:locationId?/:itemsId?" element={<AktuelleWerbung />} />
          <Route path="partner/:partnerName?" element={<PartnerDetail />} />
          <Route path="artikel/:cat?/:filter?/:brand?" element={<Articles />} />
          <Route path="ProductDetailPage/:id?" element={<ProductDetailPage />} />
          <Route element={<Home currentLocation={Article}  />} />
          <Route path="filialen"  element={ <ContactUs /> } />
          <Route path="aktionen"  element={ <ContactUs /> } />
          <Route path="projektanfrage"  element={ <ContactUs /> } />
          <Route path="jobs"  element={ <ContactUs /> } />
          <Route path="partners"  element={ <PartnersList /> } />
          <Route path="aufnachwolfen"  element={ <WeeklyHighlight /> } />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
        <Route path="/data"  element={ <AktuelleWerbung /> } />
      </Routes>
    </BrowserRouter>
  </div>
  );
}

export default App;
