import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import apiConfig from "../../apiConfig";
import PartnerArticles from "./PartnerArticles";
import DynamicCSSComponent from "./DynamicCSSComponent";
import IdeaSlider from "./IdeaSlider";

function PartnerDetail() {
  const [partnerDetail, setPartnerDetail] = useState("");
  const [countIdea, setCountIdea] = useState("");
  let { partnerName } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiConfig.apiBasePath}getPatnerSections`,
          {
            method: "POST",
            body: JSON.stringify({
              name: decodeURIComponent(partnerName),
            }),
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setPartnerDetail(data);
        setCountIdea(
          12 / data?.ideas &&
            Array.isArray(data.ideas) &&
            data.ideas.filter((item) => item.idea_type === "big idea").length
        ); // Use the response data as needed
      } catch (error) {
        console.error("There was a problem with your fetch operation:", error);
      }
    };
    fetchData();
  }, [partnerName]);

  let slider = {
    arrows: true,
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (
    partnerDetail?.partner_detail &&
    (partnerDetail.partner_detail.id === "33" ||
      partnerDetail.partner_detail.id === "35" ||
      partnerDetail.partner_detail.id === "42" ||
      partnerDetail.partner_detail.id === "44" ||
      partnerDetail.partner_detail.id === "39" ||
      partnerDetail.partner_detail.id === "46" ||
      partnerDetail.partner_detail.id === "48" ||
      partnerDetail.partner_detail.id === "52" ||
      partnerDetail.partner_detail.id === "55" ||
      partnerDetail.partner_detail.id === "56" ||
      partnerDetail.partner_detail.id === "36")
  ) {
    slider = {
      arrows: true,
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
  }

  if (
    partnerDetail?.partner_detail &&
      partnerDetail.partner_detail.id === "44"
  ) {
    slider = {
      arrows: false,
      dots: true,
      autoplay:true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
  }

  const settings = slider;
  console.log("settings",settings);

  return (
    <>
      {partnerDetail?.partner_detail && (
        <DynamicCSSComponent partnerID={partnerDetail?.partner_detail.id} />
      )}
      {partnerDetail?.idea_slider &&
        partnerDetail.partner_detail.id === "48" &&
        Object.keys(partnerDetail?.idea_slider).map((key) => {
          if (key === "312") {
            return (
              <IdeaSlider
                partnerDetail={partnerDetail}
                settings={{
                  arrows: true,
                  dots: false,
                  infinite: true,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                }}
                keyId={key}
                key={key}
              />
            );
          } else {
            return null; // Optionally return null or handle other cases
          }
        })}

      {partnerDetail?.idea_slider &&
        (partnerDetail.partner_detail.id == "36" ||
          partnerDetail.partner_detail.id == "42" ||
          partnerDetail.partner_detail.id == "46" ||
          partnerDetail.partner_detail.id == "55" ||
          partnerDetail.partner_detail.id == "56") &&
        Object.keys(partnerDetail?.idea_slider).map((key) => (
          <IdeaSlider
            partnerDetail={partnerDetail}
            settings={settings}
            keyId={key}
          />

          // console.log(key,'key first')
        ))}
      {partnerDetail?.section &&
        partnerDetail?.section.some((item) =>
          item.section_name.includes("Header Image")
        ) && (
          <section className="innerPageBanner">
            <img
              src={`../assets/${partnerDetail?.header_Image?.image_url}`}
              alt=""
            />
          </section>
        )}

      <div className="innepageBody Neff_product-wrapper">
        <div className="innerPageBottom">
          {partnerDetail?.section &&
            partnerDetail?.section.some((item) =>
              item.section_name.includes("promise section")
            ) && (
              <section className="regionalPartner pb-5 mb-3">
                <div className="container">
                  {partnerDetail?.promise.length > 0 && (
                    <h2 className="partern_head">
                      {partnerDetail.promise[0].title}
                    </h2>
                  )}

                  {partnerDetail?.promise.length > 0 && (
                    <h4>{partnerDetail?.promise[0].subTitle}</h4>
                  )}

                  <ul>
                    {partnerDetail?.promise &&
                      partnerDetail?.promise.map((item, index) => (
                        <li key={index}>
                          {/* <img
                            src="../assets/images/check-icon.png"
                            alt="check"
                          /> */}
                          <span style={{ textAlign: "left" }}>{item.name}</span>
                        </li>
                      ))}
                  </ul>
                </div>
              </section>
            )}

          {partnerDetail?.idea_slider &&
           ( 
            partnerDetail.partner_detail.id == "33"
            ||
            partnerDetail.partner_detail.id == "44"


            )
            &&
            Object.keys(partnerDetail?.idea_slider).map((key) => (
              <IdeaSlider
                partnerDetail={partnerDetail}
                settings={settings}
                keyId={key}
              />
            ))}

          {partnerDetail?.section &&
            partnerDetail?.section.some((item) =>
              item.section_name.includes("ideas")
            ) &&
            Object.keys(partnerDetail?.ideas).map((key) => (
              <section className={`inspireBig py-5 mb-2 ${key}`}>
                <div className="container">
                  {partnerDetail?.ideas[key]
                    .filter((item) => item.idea_type === "heading big")
                    .map((item, index) => (
                      <>
                        {item.title && (
                          <h2 className="partern_head ideas_head">
                            {item.title}
                          </h2>
                        )}
                        {item.description && (
                          <h4
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          ></h4>
                        )}
                        {item.image.endsWith(".mp4") ? ( // Check if image is a video
                          <span>
                            <video controls>
                              <source
                                src={`../assets/${item.image}`}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </span>
                        ) : (
                          (item.image.endsWith(".png") ||
                            item.image.endsWith(".svg") ||
                            item.image.endsWith(".jpg")) && (
                            <span>
                              <img src={`../assets/${item.image}`} alt="card" />
                            </span>
                          )
                        )}
                      </>
                    ))}

                  <div className="row">
                    {partnerDetail?.ideas[key]
                      .filter((item) => item.idea_type === "big idea")
                      .map((item, index) => (
                        <div
                          className={`col-12 col-md-${
                            12 /
                            partnerDetail?.ideas[key].filter(
                              (item) => item.idea_type === "big idea"
                            ).length
                          } p-0`}
                          key={index}
                        >
                          <div className="blog-card">
                            <div className="IdeasCardInner">
                              {item.image.endsWith(".mp4") ? ( // Check if image ends with .mp4 (assuming it's a video)
                                <video controls>
                                  <source
                                    src={`../assets/${item.image}`}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              ) : (
                                <img
                                  src={`../assets/${item.image}`}
                                  alt="card"
                                />
                              )}
                              {item.title && item.title.trim() !== "" && (
                                <h3
                                  dangerouslySetInnerHTML={{
                                    __html: item.title,
                                  }}
                                ></h3>
                              )}
                              {item.description &&
                                item.description.trim() !== "" && (
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                  ></p>
                                )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </section>
            ))}

          {partnerDetail?.idea_slider &&
            partnerDetail.partner_detail.id === "48" &&
            Object.keys(partnerDetail?.idea_slider).map((key) => {
              if (key != "312") {
                return (
                  <IdeaSlider
                    partnerDetail={partnerDetail}
                    settings={{
                      arrows: true,
                      dots: false,
                      infinite: true,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    }}
                    keyId={key}
                    key={key}
                  />
                );
              } else {
                return null; // Optionally return null or handle other cases
              }
            })}

          {partnerDetail?.idea_slider &&
            partnerDetail.partner_detail.id != "33" &&
            partnerDetail.partner_detail.id != "42" &&
            partnerDetail.partner_detail.id != "48" &&
            partnerDetail.partner_detail.id != "44" &&
            partnerDetail.partner_detail.id != "46" &&
            partnerDetail.partner_detail.id != "55" &&
            partnerDetail.partner_detail.id != "56" &&
            partnerDetail.partner_detail.id != "36" &&
            Object.keys(partnerDetail?.idea_slider).map((key) => (
              <IdeaSlider
                partnerDetail={partnerDetail}
                settings={settings}
                keyId={key}
              />
            ))}

          {partnerDetail?.partner_detail && (
            <PartnerArticles partner_detail={partnerDetail?.partner_detail} />
          )}
        </div>
      </div>
    </>
  );
}

export default PartnerDetail;
